body{
  margin:0 auto;
  text-align: center;
  padding: 1em;
}
.material-icons{
  margin:5px;
  
}
.material-icons:hover{
  cursor: pointer;
}
img{
  margin-top: 1.5em;
 
 

}
#add_task{
  color:green;
}
.remove_task{
  color:red;
}
#add_task:hover, .remove_task:hover{
  color:slateblue;
}
.deleteTaskSection{
  text-align: right;
  width: 0.5%;
}
.options{
  width:5px;
}
.task{
  text-align: left;
  font-size: 1.2em;
}